// @import "~normalize.css/normalize";

// @import "~foundation-sites/scss/_global";
// @import "~foundation-sites/scss/components/_table";
// @import "~foundation-sites/scss/components/_table";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "_variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/utilities/text";

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,600');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

body {
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
}

.App {
  display: grid;
  grid-template-rows: 50px 50px 1fr auto;
  grid-template-columns: $menu_width minmax(($menu_width * 2), ($max-width - $menu_width));
  grid-gap: 10px 15px;
  justify-content: center;
  min-height: 100vh;
}

.border-error,
.form-control.border-error {
  border-color: $brand-danger;
}

input.border-error {
  &:focus {
    box-shadow: $input-focus-box-shadow-error;
  }
}

.card {
  // width: 100%;
  padding: 15px;
  border: 1px solid $border-color;
  border-radius: $section-radius;
  background-color: $navbar-default-bg;
  box-shadow: $section-shadow;

  &.fill-height {
    height: 100%;
  }
}

.table th {
  border-top: 0;
}




// // checkbox fix
// .bp3-control.bp3-checkbox input:checked ~ .bp3-control-indicator::before {
//   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z' fill='white'/%3e%3c/svg%3e");
// }

// // old styles
// .App-logo {
//   animation: App-logo-spin infinite 20s linear;
//   height: 80px;
// }

// .App-header {
//   background-color: #222;
//   height: 150px;
//   padding: 20px;
//   color: white;
// }

// .App-title {
//   font-size: 1.5rem;
// }

// .App-intro {
//   font-size: large;
// }

// @keyframes App-logo-spin {
//   from { transform: rotate(0deg); }
//   to { transform: rotate(360deg); }
// }

// a:focus {
//   outline: none;
// }