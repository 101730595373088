@import "../../../variables";
@import "~cropperjs/dist/cropper.css";

#image-cropper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: $overlay-background;

  .inner-container {
    width: 86vw;
    max-width: ($max-width * 0.86);
    height: 80vh;
    max-height: ($max-width * 0.45);
    margin: 10vh auto;

    .cropper-wrapper {
      height: calc(100% - 40px);
      margin-bottom: 10px;
    }

    .button-row {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 10px;
      }
    }
  }
}
