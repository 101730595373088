@import '../../variables';

aside {
  grid-column: 1;
  grid-row: 2 / 4;

  margin-left: 15px;
  border: 1px solid $border-color;
  border-radius: $section-radius;
  background-color: $navbar-default-bg;

  &.card {
    padding: 0;
  }
}

.main-menu {
  position: sticky;
  top: 0px;

  display: flex;
  flex-direction: column;
  padding: 30px 0;
  font-size: 1.02rem;

  a {
    height: 40px;
    padding: 0 40px;
    display: flex;
    align-items: center;
    color: lighten($text-color, 30%);
    text-decoration: none;

    &:hover {
      background-color: $body-bg;
      color: $text-color;
    }

    .active {
      color: $text-color;
    }
  }

  .menu-icon {
    margin-right: 10px;
  }
}

