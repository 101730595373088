@import './../../variables';

.pages-table {
  tr th {
    border: 0;
    padding: 0.3rem 0.5rem;
  }

  tr td {
    padding: 0.5rem;
    cursor: pointer;
  }
}