@import '../../variables';

#page-header {
  grid-column: 2;
  grid-row: 2 / 3;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-right: 15px;
  padding: 0 15px;

  border: 1px solid $border-color;
  border-radius: $section-radius;
  background-color: $navbar-default-bg;

  border-bottom: 1px solid $panel-default-border;
  color: lighten($text-color, 10%);

  h1 {
    margin: 0;
    padding-left: 10px;
    font-size: 1.25rem;
    line-height: 1;
    font-weight: 100;
  }
}

.page-header-buttons {

  & > a,
  & > button,
  & > span {
    display: inline-block;
    margin-left: 10px;
  }

  button,
  button:hover {
    background-color: #ffffff00;
    color: $text-color;
  }

  button:hover {
    text-decoration: underline;
  }
}