@import '../../../variables';

#load-animation {
  position: fixed;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $overlay-background;

  svg {
    width: 40px;
    height: 40px;
    color: #444;
    animation: logo-animation-spin infinite 1s ease-in-out;
  }

  .load-animation-gif {
    width: 135px;
    height: 135px;
    background-image: url("./load_animation_short_lg.gif");
    background-size: 135px;
  }
}


@keyframes logo-animation-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}