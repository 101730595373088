@import '../variables';

#build-status {
  div {
    line-height: 1.7;
  }

  strong {
    display: inline-block;
    width: 60px;
  }
}