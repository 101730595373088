@import "../../_variables";

footer {
  grid-column: 1 / 3;
  grid-row: 4;

  width: 100%;
  height: 40px;
  border-top: 1px solid $border-color;
  font-family: 'Source Sans Pro', sans-serif;
  background-color: white;
  font-size: $font-size-base * 0.9;

  div {
    display: grid;
    height: 100%;
    align-items: center;
    justify-items: center;
  }

  a {
    color: $brand-primary;
    text-decoration: none;
  }
}