@import '../../../variables';

.dropzone {
  outline-width: 0;

  &.dropzone--isActive {
    border: 1px dashed $border-color;
  }

  .button,
  p {
    margin: 10px auto;
  }

  p {
    text-align: center;
  }
}