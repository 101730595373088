@import '../../_variables';

#login {
  grid-column: 1 / 3;
  grid-row: 2 / 4;
  align-self: start;

  margin: 70px auto;
  padding: 25px 15px;
  width: 375px;

  .form-group {
    margin-bottom: 1.5rem;
  }

  .form-group:last-of-type {
    margin-bottom: 0rem;
  }
}

.login-submit {
  float: right;
}