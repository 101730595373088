@import '../../variables';

#page-form {
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 40px;
  align-items: stretch;

  label {
    margin-bottom: 0.2rem;
    margin-top: 15px;
  }

  label:first-child {
    margin-top: 0px;
  }

  .page-content {
    height: 100%;
  }
}