@import '../../variables';

.instructor-card {
  height: 230px;
  cursor: pointer;

  &:hover {
    border-color: $brand-primary-light;
  }

  .name {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .photo {
    height: 150px;
    text-align: center;
    margin-top: 15px;

    img{
      max-width: 138px;
      max-height: 150px;
    }
  }

  .icons {
    margin-top: 15px;
    text-align: right;

    svg {
      color: lighten($text-color, 20%);
    }
  }
}