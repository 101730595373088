@import '../../../variables';

.block-load-animation {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  .load-animation-gif {
    width: 135px;
    height: 135px;
    background-image: url("./load_animation_short_lg.gif");
    background-size: 135px;
  }
}
