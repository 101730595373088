@import '../../variables';

#instructor-edit-page {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 4%;

  .photo-container {
    text-align: center;

    .dropzone {
      min-height: 75px;
    }

    .photo {
      position: relative;

      svg {
        display: none;
        position: absolute;
        right: 0px;
        color: $brand-danger;
        font-size: 24px;
        top: 0px;
        cursor: pointer;
      }

      img {
        max-width: 100%;
      }

      &:hover {
        svg {
          display: inline-block;
        }
      }
    }
  }
}