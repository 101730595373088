@import "../../_variables";

#main-header {
  grid-column: 1 / 3;
  grid-row: 1;

  display: grid;
  grid-template-columns: 0px ($menu_width - 0px + 150px) auto;
  height: 50px;
  align-items: center;
  line-height: 1;
  background-color: $brand-primary;
  color: white;

  .menu-icon {
    // padding: 15px;
    cursor: pointer;

    ion-icon {
      font-size: 22px;
      color: white;
    }
  }

  .logo {
    padding: 0 15px 3px 15px;

    a {
      font-family: 'Open Sans', sans-serif;
      font-size: 17px;
      text-decoration: none;
      color: white;
    }
  }

  .headerTitle {
    width: 100%;

    .right-nav-icon {
      float: right;
      margin-right: 10px;
      padding: 2px;
      cursor: pointer;
    }
  }
}
