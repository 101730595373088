@import '../../variables';

#group-form {
  display: grid;
  grid-template-columns: minmax(300px, 470px) 180px;
  grid-column-gap: 50px;
  grid-row-gap: 15px;

  .group-schedule {
    grid-column: 1 / 2;

    .group-schedule-day {
      display: grid;
      grid-template-columns: 120px 240px 100px;
      margin-top: 5px;

      &:first-child {
        margin-top: 0px;
      }

      .time-seporator {
        display: inline-block;
        margin: 0 5px;
      }

      .small-room-header {
        grid-column: 3 / 3;
        text-align: center;
      }
    }
  }

  .group-comment {
    grid-column: 1 / 2
  }

  input:not([type=checkbox]),
  select,
  textarea {
    width: 100%;
  }

  .group-schedule-day input:not([type=checkbox]) {
    width: auto;
  }
}