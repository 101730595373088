@import '../../../variables';

#notifications {
  position: fixed;
  right: 0px;
  top: 0px;
  font-size: 0.95em;

  .notification-item {
    width: 300px;
    padding: 10px 15px;
    margin: 7px 15px;
    border: 1px solid $brand-info;
    border-top: 3px solid $brand-info-transparent;
    border-radius: $border-radius;
    color: $text-color;
    background-color: white;
    animation-duration: 3s;
    animation-name: slidein;

    &.error {
      border: 1px solid $brand-danger;
      border-top: 3px solid $brand-danger-transparent;
    }
  }
}

@keyframes slidein {
  0% {
    margin-left: 100%;
  }

  20% {
    margin-left: 0%;
  }

  95% {
    margin-left: 0%;
  }

  100% {
    margin-left: 100%;
  }
}