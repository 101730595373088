#instructor-form {
  display: grid;
  grid-column-gap: 50px;
  grid-template-columns: 1fr 1fr 100px;

  .form-group {
    margin: 11px 0;
    // flex-flow: row nowrap;

    label {
      margin-right: 10px;
    }

    input[type="text"],
    textarea {
      width: 100%;
    }

    .instructor-form-archive {
      text-align: right;
    }
  }
}