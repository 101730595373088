@import '../../../variables';

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  min-height: 30px;

  border: 1px solid transparent;
  border-radius: $border-radius;
  -webkit-transition: background-color 0.15s ease-out, color 0.15s ease-out;
  transition: background-color 0.15s ease-out, color 0.15s ease-out;
  font-size: 0.95rem;
  -webkit-appearance: none;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: $brand-info;
  color: #fefefe;

  .element-load-animation,
  .button-icon {
    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }
  }
}

.button-success {
  background-color: $brand-success;

  &:hover {
    background-color: darken($brand-success, 5%);
  }
}

.button-danger {
  background-color: $brand-danger;

  &:hover {
    background-color: darken($brand-danger, 10%);
  }
}

.button-default {
  background-color: $button-default;

  &:hover {
    background-color: darken($button-default, 10%);
  }
}