@import '../variables';

#main-content {
  grid-column: 2;
  grid-row: 3 / 4;
  align-self: start;

  margin-right: 15px;
  padding: 15px;
}
