// Body
$body-bg: #f0f3f5;
$max-width: 1600px;

// Colors
$brand-primary: #2574a2;
$brand-primary-dark: #0F5E8C;
$brand-primary-light: #62A7D0;
$brand-info: #408BB7;
$brand-success: #21B268;
$brand-warning: #FEC72E;
$brand-danger:  #FE602E;
$border-color: #d2d6de;
$button-default: #abb3b7;

$brand-info-transparent: rgba(141, 195, 227, 0.90);
$brand-success-transparent: rgba(56, 200, 127, 0.90);
$brand-warning-transparent: rgba(255, 206, 71, 0.90);
$brand-danger-transparent: rgba(255, 115, 71, 0.90);

$overlay-background: rgba(0, 0, 0, 0.2);

// Typography
$font-family-sans-serif: 'Source Sans Pro', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.5;
$text-color: #0e0f0f;

// sections
$section-bg: #fff;
$section-radius: 2px;
$section-shadow: 0px 0px 2px #e9e9e9;

// Borders
// $border-color: darken($body-bg, 10%);
$list-group-border: $border-color;
$navbar-default-border: $body-bg;
$panel-default-border: $border-color;
$panel-inner-border: $border-color;
$border-radius: 3px;

// forms
$input-btn-focus-width: 0.05rem;
$input-focus-box-shadow: 0 0 0 $input-btn-focus-width $brand-primary-light;
$input-focus-box-shadow-error: 0 0 0 $input-btn-focus-width $brand-danger;
$input-font-size-sm: 0.9rem;

// Navbar
$navbar-default-bg: $section-bg;
$menu_width: 230px;

$table-hover-bg: $body-bg;

// // Buttons
// $btn-default-color: $text-color;
// $input-btn-padding-y: 0.2rem;
// $input-btn-padding-x: 1rem;

// // Inputs
// $input-border: lighten($text-color, 40%);
// $input-border-focus: lighten($brand-primary, 25%);
// $input-color-placeholder: lighten($text-color, 30%);

// // Panels
// $panel-default-heading-bg: #fff;

// // Forms
// // $form-group-margin-bottom: 10px;