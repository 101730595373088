@import '../../variables';

.groups {
  margin-top: 35px;

  &:first-child {
    margin-top: 0px;
  }

  th:first-child,
  td:nth-child(2) {
    // width: 30px;
    width: 0px;
  }

  td:nth-child(1n+4):not(:last-child),
  th:nth-child(1n+4):not(:last-child) {
    width: 7%;
    min-width: 80px;
    text-align: center;
    border-right: 1px solid $border-color;
  }

  td:nth-child(1n+3):not(:last-child),
  th:nth-child(1n+3):not(:last-child) {
    border-right: 1px solid $border-color;
    border-right: 1px solid $border-color;
  }

  td:nth-child(3),
  td:last-child {
    width: 15%;
  }

  th.groups-header {
    font-size: 1.1rem;
    cursor: pointer;

    .groups-header-title {
      float: left;
      cursor: pointer;
      line-height: 1;
    }

    .action-icons {
      display: none;
      color: lighten($text-color, 20%);
      font-size: 1.0rem;

      svg {
        margin-left: 15px;
      }
    }

    &:hover .action-icons,
    .action-icons.visible {
      display: inline;
    }

    & .remove-icon:hover {
      color: $brand-danger;
    }
  }

  th .groups-header-edit {
    display: flex;
    align-items: center;
    justify-content: space-between;

    form {
      width: calc(100% - 80px);
      font-size: 1.1rem;
    }

    input {
      width: 100%;
    }
  }

  .action-icons {
    float: right;
    cursor: pointer;
    color: lighten($text-color, 20%);
    font-size: 1.0rem;

    svg {
      margin-left: 15px;
    }
  }

  .action-icons-not-visible {
    float: right;
    color: $section-bg;
  }

  td {
    cursor: pointer;
    border-bottom: 1px solid $border-color;

    &.hall-small{
      background-color: lighten($brand-warning, 20%);
    }
  }

  .groups-header.no-groups {
    color: #aaa;
  }
}

